<table mat-table [dataSource]="items">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="itemName">
        <th mat-header-cell *matHeaderCellDef> Désignation </th>
        <td mat-cell *matCellDef="let element"> {{element.itemName}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lotNumber">
        <th mat-header-cell *matHeaderCellDef> N° de lo </th>
        <td mat-cell *matCellDef="let element"> {{element.lotNumber}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef> N° de série </th>
        <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="manufacturingDate">
        <th mat-header-cell *matHeaderCellDef> Date de production </th>
        <td mat-cell *matCellDef="let element"> {{element.manufacturingDate}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef> Date de préemption </th>
        <td mat-cell *matCellDef="let element"> {{element.expirationDate}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

