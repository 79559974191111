<div class="fx-layout-column fx-content-center fx-items-center">
  <div
    class="exam-grid fx-layout-column fx-content-space-between fx-items-center fx-gap-8"
    style="width: 600px; height: calc(100vh - 150px)"
  >
    <div class="fx-layout-column fx-overflow-auto">
      <div class="a-table">
        <div class="a-row a-header">
          <div>{{ "AET_S" | translate }}</div>
          <div>{{ "TOTAL" | translate }}</div>
          <div [style.text-align]="'right'" style="padding-right: 16px">
            {{ "TOTAL_PAID" | translate }}
          </div>
        </div>
        @for (aet of dataset; track $index) {
        <div class="a-row">
          <div>{{ aet.label }}</div>
          <div>{{ aet.count }}</div>
          <div [style.text-align]="'right'" style="padding-right: 16px">
            {{ aet.totalPaid }}
          </div>
        </div>
        }
        <div class="a-row a-footer">
          <div style="text-align: center">{{ "TOTAL_EXAMS" | translate }}:</div>
          <div>{{ total }}</div>
          <div [style.text-align]="'right'" style="padding-right: 16px">
            {{ totalPaid }}
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        display: block;
        min-height: 320px;
        min-width: 600px;
        padding: 12px 0 32px 0;
        border-top: 1px solid #e7e7e7;
      "
      class="fx-layout-column fx-content-space-between fx-items-center fx-gap-6"
    >
      <div style="text-align: center">
        <mat-button-toggle-group (change)="changeGraphData($event.value)">
          <mat-button-toggle [checked]="true" value="count">
            {{ "SUM_EXAMS" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="totalPaid">
            {{ "SUM_PAID" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <ft-chart
        [data]="pieData"
        [labels]="pieLabels"
        [type]="'pie'"
        [title]="'AETS'"
        [chartId]="'aet'"
      ></ft-chart>
    </div>
  </div>
</div>
