import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StatisticDataModel } from './model';
import { PhysicianExamStateComponent } from './physician-exam-state/physician-exam-state.component';
import { ReferringExamStateComponent } from './referring-exam-state/referring-exam-state.component';
import { AetExamStateComponent } from './aet-exam-state/aet-exam-state.component';
import { TechnicianExamStateComponent } from './technician-exam-state/technician-exam-state.component';
import { FtPivotTableComponent } from './ft-pivot-table/ft-pivot-table.component';
import { ReasonForExamStateComponent } from './reason-for-exam-state/reason-for-exam-state.component';
import { PaymentDateStateComponent } from './payment-date-state/payment-date-state.component';
import { PatientStateComponent } from './patient-state/patient-state.component';
import { GeneralSettingDTO } from '../model';
import { AppConfigService } from '../app-config.service';
import { PrintFilesComponent } from './print-files/print-files.component';
import {FungibleComponent} from "./fungible/fungible.component";
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'ft-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class StatisticComponent {
	static COMPONENTS = {
		EXAM: {
			GENERAL_VIEW: FtPivotTableComponent,
			PRINT_FILES: PrintFilesComponent,
			PERFORMING_RADIOLOGIST: PhysicianExamStateComponent,
			REFERRING_PHYSICIAN: ReferringExamStateComponent,
			TECHNICIANS: TechnicianExamStateComponent,
			AET_S: AetExamStateComponent,
			REASONS_FOR_EXAM: ReasonForExamStateComponent,
		},
		PATIENT: {
			ALL: PatientStateComponent,
			FUNGIBLE: FungibleComponent,
		},
		PAYMENT: {
			DATE: PaymentDateStateComponent,
		},
	};

	dataset: Observable<StatisticDataModel[]>;
	generalSetting: GeneralSettingDTO;

	constructor(private _config: AppConfigService) {
		this.generalSetting = this._config.generalSetting;
	}
}
