import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StateComponent, ViewQuery } from '../model';
import { StatisticService } from '../statistic.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { AttributeState } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { FtChartComponent } from '../../shared/ft-chart/ft-chart.component';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';

@Component({
    selector: 'ft-reason-for-exam-state',
    templateUrl: './reason-for-exam-state.component.html',
    styleUrls: ['./reason-for-exam-state.component.scss'],
    standalone: true,
    imports: [
        MatButtonToggleGroup,
        MatButtonToggle,
        FtChartComponent,
        TranslateModule,
    ],
})
export class ReasonForExamStateComponent
	implements StateComponent, OnInit, OnDestroy
{
	@Input() data: ViewQuery;

	private sub: Subscription;
	total: number;
	totalPaid: number;

	public dataset!: AttributeState[];
	private _service = inject(StatisticService);

	public pieData!: any[];
	public pieLabels!: any[];

	changeGraphData(key: string) {
		this.pieData = this.dataset.map(it => it[key]);
	}

	ngOnInit() {
		const start = moment(this.data.startDate).format('YYYYMMDD');
		const end = moment(this.data.endDate).format('YYYYMMDD');

		this.sub = this._service
			.getExamsByType(`${start}-${end}`)
			.subscribe(data => {
				this.dataset = data;

				this.pieData = this.dataset.map(it => it.count);
				this.pieLabels = data.map(it => it.label);

				this.total = this.dataset
					.map(it => it.count)
					.reduce((a, c) => a + c, 0);
				this.totalPaid = this.dataset
					.map(it => parseFloat(it.totalPaid.toFixed(2)))
					.reduce((a, c) => a + c, 0);
			});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
