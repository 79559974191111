import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { StateComponent, ViewQuery } from '../model';
import { StatisticService } from '../statistic.service';
import { map as _map } from 'lodash';
import { BehaviorSubject, merge, of as observableOf, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import moment from 'moment';
import { ReferringPhysicianStatsDTO } from '../../model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { RPH_TABLE_CONF } from './table-conf';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';

@Component({
    selector: 'ft-referring-exam-state',
    templateUrl: './referring-exam-state.component.html',
    styleUrls: ['./referring-exam-state.component.scss'],
    animations: [
        rowsAnimation,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                display: 'none',
                visibility: 'hidden',
                zIndex: '-1',
            })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        NgClass,
        MatProgressSpinner,
        MatPaginator,
        TranslateModule,
    ],
})
export class ReferringExamStateComponent
	implements StateComponent, AfterViewInit, OnDestroy
{
	@Input() data: ViewQuery;

	displayedColumns = [];
	columnsToDisplay = [];
	availableColumns = [];
	dataSource = new MatTableDataSource<ReferringPhysicianStatsDTO>();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	trackById = (index: number, item: any): string => item.physicianName;
	private query = new BehaviorSubject<any>(null);
	private sub: Subscription;

	constructor(private service: StatisticService) {
		this.displayedColumns = RPH_TABLE_CONF;
		this.availableColumns = RPH_TABLE_CONF.filter(item => !item.hidden);

		this.columnsToDisplay = _map(
			this.availableColumns.filter(c => !c.hidden),
			'value'
		);
	}

	ngAfterViewInit(): void {
		this.query.next({
			startDate: this.data.startDate,
			endDate: this.data.endDate,
			referringPhysicianId: this.data.filter,
		});

		const observedFilters = [
			this.paginator.page.asObservable(),
			this.query,
		];

		this.sub = merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;

					const query = this.query.getValue();
					const start = moment(query.startDate).format('YYYYMMDD');
					const end = moment(query.endDate).format('YYYYMMDD');
					const physicianId = query.referringPhysicianId || 0;

					const dateRange = `${start}-${end}`;
					return this.service.getExamsPerReferringPhysicians(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						dateRange,
						physicianId
					);
				}),
				tap(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
				}),
				map(data => data['content'] as ReferringPhysicianStatsDTO[]),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => {
				this.dataSource.data = data;
			});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
