import {AfterViewInit, Component, inject, Input, OnDestroy} from '@angular/core';
import {FtState, StateComponent, ViewQuery} from "../model";
import {Subscription} from "rxjs";
import {StatisticService} from "../statistic.service";
import moment from "moment/moment";
import {MatTableModule} from "@angular/material/table";

@Component({
  selector: 'ft-fungible',
  standalone: true,
  imports: [
    MatTableModule
  ],
  templateUrl: './fungible.component.html',
  styleUrl: './fungible.component.scss'
})
export class FungibleComponent implements StateComponent, OnDestroy, AfterViewInit {
  @Input() data: ViewQuery;

  private sub: Subscription;
  private _service = inject(StatisticService);


  items = []
  displayedColumns = ['itemName', 'lotNumber', 'serialNumber', 'manufacturingDate', 'expirationDate'];


  ngAfterViewInit() {
    const start = moment(this.data.startDate).format('YYYYMMDD');
    const end = moment(this.data.endDate).format('YYYYMMDD');

    console.log(start);

    this.sub = this._service
        .getFungibleState(`${start}-${end}`)
        .subscribe(data => {
          this.items = start.includes('202405') ? data:[]
        });
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
